var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "control-table-header df jc-sb ai-c" }, [
    _c(
      "div",
      { staticClass: "control-table-header__search" },
      [
        _c("el-input", {
          staticClass: "table-input",
          style: { maxWidth: _vm.searchWidth },
          attrs: {
            placeholder: _vm.searchPlaceholder,
            "prefix-icon": "el-icon-search",
            clearable: "",
          },
          on: {
            clear: function ($event) {
              return _vm.$emit("clear")
            },
          },
          nativeOn: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.$emit("search")
            },
          },
          model: {
            value: _vm.value,
            callback: function ($$v) {
              _vm.value = $$v
            },
            expression: "value",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }