<template>
  <div class="control-table-header df jc-sb ai-c">
    <div class="control-table-header__search">
      <el-input
        v-model="value"
        :placeholder="searchPlaceholder"
        prefix-icon="el-icon-search"
        class="table-input"
        :style="{ maxWidth: searchWidth }"
        clearable
        @clear="$emit('clear')"
        @keyup.enter.native="$emit('search')"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableHeader',
  props: {
    search: { type: String, default: '' },
    searchPlaceholder: { type: String, default: 'по номеру авто' },
    searchWidth: { type: String, default: '195px' },
  },
  computed: {
    value: {
      get() {
        return this.search
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
}
</script>

<style lang="sass">
.control-table-header
  padding: $space-20 $space-28
  background: #F4F7FC
  border-radius: 6px 6px 0 0
  +to(1024px)
    flex-wrap: wrap

  &__search
    flex: 0 0 21%
    width: 20%
    .el-input__inner::placeholder,
    .el-input__inner::-webkit-input-placeholder
      color: lightgray
    +to(1024px)
      flex: 0 0 40%
      width: 40%
      max-width: 100%
    +to(700px)
      flex: 0 0 100%
      width: 100%

  &__checkboxes
    +to(1024px)
      flex: 0 0 60%
      width: 60%
      padding-left: 22px
      display: flex
      justify-content: center
      align-items: center
    +to(700px)
      flex: 0 0 100%
      width: 100%
      margin-top: 22px
    +to(390px)
      flex-wrap: wrap
    .el-checkbox
      +to(390px)
        flex: 0 0 100%
        margin-left: 0
        margin-top: 12px
        &:first-of-type
          margin-top: 0

  &__btn
    svg
      cursor: pointer
      transition: transform .3s ease
      &:hover
        transform: scale(1.1)
    +to(1024px)
      margin-top: 22px
      margin-left: 22px

  &__selects
    flex: 0 0 40%
    max-width: 388px
    +to(1024px)
      margin-top: 22px
      flex: 1 0 auto
      max-width: 100%
    +to(700px)
      max-width: calc(100% - 70px)
      flex: 0 0 calc(100% - 70px)
    +to(500px)
      flex: 0 0 100%
      flex-wrap: wrap
    .el-select
      +to(1024px)
        flex: 1 0 auto
        max-width: 42.2%
      +to(900px)
        max-width: 42.5%
      +to(800px)
        max-width: 42.8%
      +to(700px)
        max-width: calc(100% - 50px)
        flex: 0 0 50%
      +to(500px)
        flex: 0 0 100%
        margin-top: 22px
        margin-left: 0
        max-width: 100%
        &:first-of-type
          margin-top: 0
</style>
